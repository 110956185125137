<template>
    <div class="container">
        <img src="../../assets/banner/about-title.png" alt="">
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    height: 320px;
    background: url('../../assets/banner/about.png') no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:0;
    margin:0;
}
</style>