<template>
    <div class="corner-title">
        <span class="lt-text">{{ltText}}</span>
        <span class="line"></span>
        <span class="rb-text">{{rbText}}</span>
    </div>
</template>

<script>
export default {
    name: 'CornerTitle',
    props: ['ltText', 'rbText'],
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.corner-title{
    position: relative;
    width: 160px;
    height: 66px;
    color: #666666;
    margin-left: calc(50% - 80px);
    overflow: hidden;
    .lt-text{
        font-size: 48px;
        font-weight: bold;
        position: absolute;
        z-index: 1;
        top: 2px;
        left: 0;
        line-height: 36px;
    }
    .line{
        position: absolute;
        z-index: 2;
        left: 22px;
        bottom: -44px;
        width: 88px;
        height: 88px;
        border-top: 1px solid #000;
        background: #FFFFFF;
        transform: rotate(-45deg);
    }
    .rb-text{
        font-size: 30px;
        font-weight: bold;
        position: absolute;
        z-index: 3;
        bottom: 10px;
        left: 39px;
        line-height: 30px;
    }
}
</style>